import React from "react"
import PropTypes from "prop-types";
import { graphql } from "gatsby"
import { FaGithub, FaTwitter, FaInstagram, FaMedium } from "react-icons/fa"
import Layout from "../layouts/layout"
import theme from "../theme/theme.yaml"
import SEO from "../components/seo/seo"
import config from "../../content/meta/config"
import Contact from "../components/contact/contact";

class AboutPage extends React.Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata: { title, description },
        },
      },
    } = this.props

    return (
      <React.Fragment>
        <SEO title={title} description={description} />
        <Layout>
          <div className="aboutContainer">
            <h1>
              Get in <span className="touch">touch</span> with me anytime:
            </h1>
            <ul>
              {/* <li>
                <FaEnvelope /> Mail
              </li> */}
              <li>
                <FaTwitter />
                <a href={config.authorSocialLinks.twitter.url}>{config.authorSocialLinks.twitter.user}</a>
              </li>
              <li>
                <FaGithub />
                <a href={config.authorSocialLinks.github.url}>{config.authorSocialLinks.github.user}</a>
              </li>
              <li>
                <FaInstagram />
                <a href={config.authorSocialLinks.Instagram.url}>{config.authorSocialLinks.Instagram.user}</a>
              </li>
              <li>
                <FaMedium />
                <a href={config.authorSocialLinks.Medium.url}>{config.authorSocialLinks.Medium.user}</a>
              </li>
            </ul>
            <Contact theme={theme}/>
          </div>
        </Layout>

        <style jsx>{`
          .aboutContainer {
            position: relative;
            background-color: ${theme.color.background.white_opac};
            z-index: 0;
            width: 95vw;
            max-width: ${`calc(${theme.text.maxWidth.desktop} *2)`};
            margin: ${`calc(${theme.header.height.homepage} + ${theme.space.xl})`} auto ${theme.space.xl};
            color: ${theme.color.neutral.gray.h};
            padding: ${theme.space.xl} ${theme.space.l};
          }

          .aboutContainer :global(h1) {
            padding: ${theme.space.m} ${theme.space.s};
            font-size: 2.5rem;
          }

          .aboutContainer ul {
            margin-top: ${theme.space.l};
            padding: ${theme.space.s} ${theme.space.l};
          }

          .aboutContainer li {
            font-size: 1.2rem;
            list-style: none;
            padding: ${theme.space.xs} 0;
          }

          .aboutContainer :global(a) {
          }

          .aboutContainer :global(svg) {
            margin: 0 1rem 0 0;
          }

          .aboutContainer li:hover {
            color: ${theme.color.neutral.category_blue};
          }

          .touch {
            font-weight: bold;
            color: ${theme.color.neutral.category_blue};
          }
        `}</style>
      </React.Fragment>
    )
  }
}

export default AboutPage

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
