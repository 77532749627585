import React from "react"
import { useFormik } from "formik"
import {
  TextField,
  createMuiTheme,
  ThemeProvider,
  Button,
} from "@material-ui/core"
import * as Yup from "yup"

const Contact = (props) => {

    const { theme } = props

    const MuiTheme = createMuiTheme({
      typography: {
        fontFamily: "sans-serif",
      },
    })
  
    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        text: "",
      },
      validationSchema: Yup.object({
        name: Yup.string().max(30, "Max. 30 characters").required("Required"),
        email: Yup.string().email("Please enter a valid email address").required("Required"),
        text: Yup.string().required(""),
      }),
      onSubmit: values => {
        sendMail(values).then(response => {
          console.log(response);
          if(response.ok) {
            alert('Thanks. Your mail was sent.' + response.json());
          } else {
            alert('There was an error sending your mail.');
          }
        });
      },
    })
  
    const sendMail = async (values) => {

      const response = await fetch('https://api.theflokster.com/mail/thefloksterMailer', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json' 
          },
          body: JSON.stringify(values),
        })
      return response;
    }
  
    return (
      <React.Fragment>
        <div id="contact" className="contactWrapper">
          <div className="contactBox">
            <ThemeProvider theme={MuiTheme}>
              <form onSubmit={formik.handleSubmit}>
                <div className="formWrapper">
                  <div className="formLeft">
                    <h2>Contact me</h2>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      id="name"
                      name="name"
                      type="text"
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
  
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      id="email"
                      name="email"
                      type="email"
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </div>
                  <div className="formRight">
                    <TextField
                      required
                      fullWidth
                      label="Message"
                      placeholder="Tell me what you think..."
                      id="text"
                      name="text"
                      type="text"
                      multiline
                      rows={15}
                      error={formik.touched.text && Boolean(formik.errors.text)}
                      helperText={formik.touched.text && formik.errors.text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.text}
                    />
  
                    <Button style={{ 'backgroundColor': theme.color.neutral.category_blue, 'color': theme.color.neutral.white }}type="submit" fullWidth color="primary">
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            </ThemeProvider>
          </div>
        </div>
  
        <style jsx>{`
          .contactWrapper {
            height: 600px;
            padding-top: 30px;
          }

          .contactWrapper h2 {
            color: ${theme.color.neutral.category_blue};
          }
  
          .contactBox {
            width: 100%;
            height: 400px;
            background-color: ${theme.color.background.white_exif};
          }
  
          .formWrapper {
            height: 100%;
            display: grid;
            grid-template-columns: [first] 40% [middle] 60% [end];
          }
  
          form {
            height: 100%;
          }
  
          .formLeft {
            grid-column-start: first;
            grid-column-end: middle;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 30px;
          }
  
          .formRight {
            padding-left: 20px;
            padding-top: 30px;
            grid-column-start: middle;
            grid-column-end: end;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          @media (max-width: 810px) {
            .formWrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
            }

            .formLeft {
              margin-top: 20px;
              padding: 0 20px 10px 20px;
            }
    
            .formRight {
              padding: 0 20px 10px 20px;
            }

            .contactBox {
              height: 600px;
            }
          }
        `}</style>
      </React.Fragment>
    )
  }
  
  export default Contact